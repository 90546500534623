import React, { useEffect, useState } from "react"
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
} from "antd"
import style from "../CarWashOptions/carWashOptions.module.css"
const Additional = ({ data, form, sendData }) => {
  const [isProductRemoved, setIsProductRemoved] = useState(false)
  const [rewash, setRewash] = useState(false)
  const [showRemarkInput, setShowRemarkInput] = useState(false)
  const getRewashRules = () => {
    if (rewash) {
      return [
        {
          required: true,
          message: "Please input This Value",
        },
      ]
    } else {
      return []
    }
  }
  const getProductRemovedRules = () => {
    if (isProductRemoved) {
      return [
        {
          required: true,
          message: "Please input This Value",
        },
      ]
    } else {
      return []
    }
  }
  const onChangeTime = (date, dateString) => {
    console.log(date, dateString)
  }
  useEffect(() => {
    const _data = data?.asset_txns_details?.asset_txns_details
    _data &&
      _data.forEach(item => {
        form.setFieldsValue({
          [item.field_name]: item.field_value,
        })
      })
  }, [])
  useEffect(() => {
    const time = sendData?.find(ele => ele?.field_name == "Time")

    const time_remakrs = sendData?.find(
      ele => ele?.field_name == "time_remakrs"
    )
    if (time?.field_value == "Other") {
      setShowRemarkInput(true)
    }
    if (time_remakrs) {
      form.setFieldsValue({
        time_remakrs: time_remakrs?.field_value,
      })
    }
  }, [])
  return (
    <div>
      <Form.Item
        label="Product Removed"
        name="product_removed"
        rules={[
          {
            required: false,
            message: "Please input your product removed!",
          },
        ]}
      >
        <Radio.Group
          onChange={e => {
            if (e.target.value == "yes") {
              setIsProductRemoved(true)
            } else {
              setIsProductRemoved(false)
            }
          }}
        >
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <div className={style.extInspection_location_nt}>
        <Form.Item
          className="car_form_item"
          label="Quantity"
          name="quantity"
          rules={getProductRemovedRules()}
        >
          <Input placeholder="eg. 2" />
        </Form.Item>

        <Form.Item
          className="car_form_item"
          label="UOM"
          name="uom"
          style={{ width: "67%" }}
        >
          <Input placeholder="eg. Drums/gallons.." />
        </Form.Item>
      </div>
      <Form.Item
        label="Additional Hand Cleaning"
        name="additional_hand_cleaning"
        rules={[
          {
            required: false,
            message: "Please input your Additional Hand Cleaning!",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item className="car_form_item" label="Time" name="Time">
        {/**<DatePicker onChange={onChangeTime} picker="time" />**/}
        <Select
          placeholder={"Select Time"}
          onChange={e => {
            if (e == "Other") {
              setShowRemarkInput(true)
            } else {
              setShowRemarkInput(false)
            }
          }}
          options={[
            { label: "0.5 Hour", value: "0.5 Hour" },
            { label: "1 Hour", value: "1 Hour" },
            { label: "1.5 Hour", value: "1.5 Hour" },
            { label: "2 Hours", value: "2 Hours" },
            { label: "2.5 Hour", value: "2.5 Hour" },
            { label: "3 Hour", value: "3 Hour" },
            { label: "Other", value: "Other" },
          ]}
        />
      </Form.Item>
      {showRemarkInput && (
        <Form.Item
          className="car_form_item"
          label="Remakrs"
          name="time_remakrs"
          rules={[
            {
              required: true,
              message: "Fill This Value",
            },
          ]}
        >
          <Input placeholder="eg. AL" />
        </Form.Item>
      )}
      <Form.Item
        label="Rewash"
        name="rewash"
        rules={[
          {
            required: false,
            message: "Please input your Rewash!",
          },
        ]}
      >
        <Radio.Group
          onChange={e => {
            if (e.target.value == "yes") {
              setRewash(true)
            } else {
              setRewash(false)
            }
          }}
        >
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        className="car_form_item"
        label="No.of Time"
        name="no_of_time"
        rules={getRewashRules()}
      >
        <Input placeholder="eg. 2" />
      </Form.Item>
    </div>
  )
}
export default Additional

import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Image } from 'antd'
import { connect } from 'react-redux'
import { truck_content } from '../../../../actions/yardActions/TruckAction'
import {
  FetchParamValue,
  IndustryType,
  renderImage,
  RAIL_CAR
} from '../../../../utils/helper'
import moment from 'moment'

const EquipmentDet = props => {
  const [state, setState] = useState('')
  const { data } = props
  useEffect(() => {
    if (props.single_truck !== null) {
      setState(props.single_truck)
    }
  }, [props.single_truck])
  return (
    <section className='equipment_oil_first'>
      <div className='equ_det_img'>
        <img
          src={renderImage(
            data?.asset_details?.equipment_type_details?.param_description
          )}
          alt='card img'
        />
        <div>
          status: <span>{data?.ticket_status?.param_description}</span>
        </div>
      </div>
      {IndustryType()==RAIL_CAR?(
              <>
          <div className='equ_details'>
          <div>
            ticket no.:<p>{data?.ticket_ref_id}</p>
          </div>
          <div>
            car no.:<p>{data?.asset_details.equipment_no}</p>
          </div>
          <div>
          car type:{' '}
            <p>
              {data?.asset_details?.equipment_type_details?.param_description}
            </p>
          </div>
          <div>
            customer name:<p>{data?.customer_details.name}</p>
          </div>
        </div>
              </>
      ):
      IndustryType() !== 'Food_Grade' ? (
        <div className='equ_details'>
          <div>
            ticket no.:<p>{data?.ticket_ref_id}</p>
          </div>
          <div>
            equipment no.:<p>{data?.asset_details.equipment_no}</p>
          </div>
          <div>
            equipment type:{' '}
            <p>
              {data?.asset_details?.equipment_type_details?.param_description}
            </p>
          </div>
          <div>
            customer name:<p>{data?.customer_details.name}</p>
          </div>
          <div>
            last contained:
            <p>
              {data?.asset_txns_details.last_known?.substr(
                data.asset_txns_details.last_known.indexOf('-') + 1
              )}
            </p>
          </div>
          <div>
            Remark:<p>{data?.remark}</p>
          </div>
        </div>
      ) : (
        <div className='equ_details'>
          <div style={{ paddingRight: '15px' }}>
            customer name:<p>{data?.customer_details.name}</p>
          </div>
          <div>
            ticket no.:<p>{data?.ticket_ref_id}</p>
          </div>
          <div>
            Checked In Date:
            <p>
              {data?.asset_txns_details?.created_at
                ? moment
                    .utc(
                      moment(data.asset_txns_details.created_at).toISOString()
                    )
                    .format('MM/DD/YYYY')
                : 'NA'}
            </p>
          </div>
          <div>
            Wash Type Requested:
            <p>
              {data?.asset_txns_details?.wash_type_requested
                ? data?.asset_txns_details?.wash_type_requested
                : 'NA'}
            </p>
          </div>
          <div>
            truck no.:
            <p>
              {data?.asset_txns_details?.truck_number
                ? data?.asset_txns_details?.truck_number
                : 'NA'}
            </p>
          </div>
          <div>
            truck unit no.:<p>-</p>
          </div>
          <div>
            last contained:
            <p>
              {data?.asset_txns_details.last_known?.substr(
                data.asset_txns_details.last_known.indexOf('-') + 1
              )}
            </p>
          </div>
          <div>
            Services:{' '}
            <p style={{ width: '100%', display: 'block' }}>
              {props.services?.length !== 0
                ? props.services?.map((item, i) => (
                    <span style={{ marginRight: 7, display: 'flex' }}>
                      {FetchParamValue(item?.service_id)}{' '}
                      {props?.services?.length - i !== 1 && ','}
                    </span>
                  ))
                : 'NA'}
            </p>
          </div>
          {props.wash_Type ? (
            <div>
              Wash Type:
              <p>
                {props.washType && (
                  <DataColumn span={24} data={props.washType} />
                )}
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </section>
  )
}

const mapStateToProps = state => ({
  truckData: state.truck.truckData,
  single_truck: state.truck.single_truck
})
export default connect(mapStateToProps, { truck_content })(EquipmentDet)

const DataColumn = ({ label, data, span }) => {
  return (
    <Col span={span}>
      <div className='eq_column'>
        <span className='eq_column_data'>{data}</span>
      </div>
    </Col>
  )
}

import React, { useState } from 'react'
import "./DateTime.css";
import calender from "../../../images/inspection/date-range-img.svg"
import watch from "../../../images/inspection/watch.svg"
import InputField from "../InputField/InputField";

const DateTime = (props) => {


    return (
        <>
            <div className="date_time">
                <div className="date_time_1">
                    <div>Select Date*</div>
                    <InputField width={props.width} className="date_filed" type="date" name="date" value={props.date} change={props.setDate}  allow={props.allow}/>
                    {/* <div className="date_img">
                        <img src={calender} alt="calender img" />
                    </div> */}
                </div>
                <div className="date_time_2">
                <div>Select Time*</div>
                    <InputField width={props.width} className="time_filed" type="time" name="time" value={props.time} change={props.setTime}  />
                    {/* <label  type="button" className="time_img" for="timePick">
                        <img src={watch} alt="watch img" />
                    </label > */}
                </div>
                <style>
                    {`
                    input[type="time"]::-webkit-calendar-picker-indicator {
                        width: 30px;
                        height:30px;
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        width: 30px;
                        height:30px;
                    }
                    
                    `}
                </style>
            </div>
        </>
    )
}
export default DateTime;
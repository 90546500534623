import React, { useState, useEffect } from "react"
import Header from "../../../components/Yard/common/Header"
import "./Inspection.css"
import CustomButton from "../../../components/Yard/Button/Button"
import { connect } from "react-redux"
import DetailsBox from "../../../components/Yard/detailBox/DetailsBox"
import TextArea from "../../../components/Yard/textArea/TextArea"
import DateTime from "../../../components/Yard/dateTime/DateTime"
import Services from "./comp/Services"
import API from "../../../api"
import { Form, message, Spin, Button, InputNumber, Select } from "antd"
import Additional from "../../../components/Additional/Additional"
import CarWashOptionsSmall from "../../../components/CarWashOptionSmall/CarWashOptionsSmall"
import PictureCard from "../../../components/upload/PictureCard"
import moment from "moment"
import { navigate } from "gatsby"
import { Routes } from "../../../utils/route"
import { IndustryType, RAIL_CAR } from "../../../utils/helper"
import YardDetails from "../../../components/data-display/card/YardDetails"
import EquipmentDet from "../equipment/comp/EquipmentDet"
import EquipmentDoc from "../equipment/comp/EquipmentDoc"
import Head from "../../../components/Yard/header/Header"
import ExtInspection from "../../../components/ExitInspection/ExtInspection"
import Expandable from "../../../components/Expandeble/Expandable"
import CarBodyDefects from "../../../components/CarBodyDefects/CarBodyDefects"
import GateDefects from "../../../components/GateDefects/GateDefects"
import CarTopDefects from "../../../components/CarTopDefects/CarTopDefects"
import InteriorInspection from "../../../components/InterirorInspection/InteriorInspection"
import MechanicalInspection from "../../../components/MachenicalInspection/MechanicalInspection"
import Arrow from "../../../assets/svg/down_arrow_icon.svg"
import TankCarInspection from "../../../components/TankCarInspection/TankCarInspection"

const Inspection = props => {
  const {
    fetchServiceDetails,
    inspectFinish,
    imageDelete,
    clientUserList,
  } = API
  const [clientList, setClientList] = useState([])

  const { Option } = Select
  const id = props.id
  const [state, setState] = useState(null)
  const [textArea, setTextArea] = useState("")
  const [image, setImage] = useState([])

  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD"),
    toString()
  )
  const [time, setTime] = useState(moment().format("HH:mm"))
  const [services, setServices] = useState(null)
  const [selectedService, setSelectedService] = useState({
    cleanServiceState: [],
    additionalServiceState: [],
  })
  const [cleanServiceState, setCleanServiceState] = useState(null)
  const [additionalServiceState, setAddtionalServiceState] = useState(null)
  // const [otherServiceState, setOtherServiceState] = useState(null);
  const [preWashImage, setPreWashImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [preCheckedServices, setPreCheckedServices] = useState(null)
  const [selectedRadioOption, setSelectedRadioOptios] = useState(null)
  const [oxygenPercentage, setOxygenPercentage] = useState(null)
  const [toxicityPPM, setToxicityPPM] = useState(null)
  const [lelPercentage, setLelPercentage] = useState(null)
  const [h2sPPM, setH2sPPM] = useState(null)
  const [showExtraFields, setShowExtraFields] = useState(false)
  const [readingList, setReadingList] = useState(null)
  const [show, setShow] = useState(false)
  const [long, setLong] = useState(1)
  const [shows, setShows] = useState(false)
  const [longs, setLongs] = useState(1)
  const [details, setDetails] = useState(null)
  const [form] = Form.useForm()
  const accordionHandler = item => {
    setLong(item.id)
    item.id === long ? setShow(!show) : setShow(true)
  }
  const accordionHandlers = item => {
    setLongs(item.id)
    item.id === longs ? setShows(!shows) : setShows(true)
  }
  useEffect(() => {
    getClientUserList()
  }, [])

  const dataSmall = [
    {
      id: 1,
      title: "Tank Car Inspection",
      description: (
        <TankCarInspection
          data={state}
          form={form}
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
  ]
  const dataAcc = [
    {
      id: 1,
      title: "External Inspection",
      description: (
        <ExtInspection
          data={state}
          form={form}
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
    {
      id: 2,
      title: "Safety appliances defects",
      description: (
        <Expandable
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
    {
      id: 3,
      title: "Car Body Defects",
      description: (
        <CarBodyDefects
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
    {
      id: 4,
      title: "Gate Defects",
      description: (
        <GateDefects
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
    {
      id: 5,
      title: "Car Top Defects",
      description: (
        <CarTopDefects
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
    {
      id: 6,
      title: "Interior Inspection",
      description: (
        <InteriorInspection
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
    {
      id: 7,
      title: "Mechanical Inspection",
      description: (
        <MechanicalInspection
          formValues={state?.asset_txns_details?.asset_txns_details}
        />
      ),
    },
  ]
  const getClientUserList = () => {
    // const listofclient = []
    clientUserList().then(res => {
      // res?.data?.data && res?.data?.data.map((item) => {
      //   listofclient.push(item.username)
      // })
      // console.log(res.data.data, "nght");
      setClientList(res?.data?.data)
    })
  }

  // const listofClient = (e) => {

  // }

  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
        if (props?.yardHandData) {
          filterEquipmentDetails()
        }
      }
    }
    Routes("yard", callback)
  }, [])

  useEffect(() => {
    if (preCheckedServices != null) {
      fetchServices()
    }
  }, [preCheckedServices])
  const onFinish = async (type, values) => {
    const ap = moment(`${date} ${time}`).format("HH:mm")
    const dMoment = moment(date).format("MM/DD/yyyy")
    const d = new Date(`${dMoment} ${ap}`)
    const s = []

    selectedService.cleanServiceState &&
      selectedService.cleanServiceState.map((val, index) => {
        s.push({
          id: val.id,
          service_id: val.service_id,
          price: val.price,
          tax: val.tax,
          sub_service_id: val.sub_service_id,
        })
      })
    selectedService.additionalServiceState &&
      selectedService.additionalServiceState.map((val, index) => {
        s.push({
          id: val.id,
          service_id: val.service_id,
          price: val.price,
          tax: val.tax,
          sub_service_id: val.sub_service_id,
        })
      })
    // otherServiceState && otherServiceState.map((val,index) =>{
    //     if(val.status === true){
    //         s.push({id:val.id,service_id:val.service_id,price:val.price,tax:val.tax,sub_service_id:val.sub_service_id})
    //     }
    // })

    if (IndustryType() !== "Food_Grade" && s.length === 0 && type === "begin") {
      message.error({
        content: `PLEASE SELECT ATLEAST ONE SERVICE TYPE TO BEGIN WASH`,
        duration: 2,
      })
    } else if (type === "Select Wash Type") {
      if (image?.length == 0) {
        return message.error("Please Uplaod Pre Wash Image")
      }

      let data = {
        images: image,
        // services: s,
        note: textArea,
        datetime: d.getTime().toString(),
        ticket_id: state.ticket_id,
        status: type == "approval" ? 3210 : 3215,
        readingBy: readingList,
        details: values,
      }
      if (h2sPPM) {
        data.h2sPPM = h2sPPM?.toString()
      }
      if (toxicityPPM) {
        data.toxicityPPM = toxicityPPM?.toString()
      }
      if (oxygenPercentage) {
        data.oxygenPercentage = oxygenPercentage?.toString()
      }
      if (lelPercentage) {
        data.lelPercentage = lelPercentage?.toString()
      }
      navigate("/yard/wash-options", {
        state: { id: id, data: state, newData: data, d: d },
      })
    } else {
      let data = {
        images: image,
        services: s,
        note: textArea,
        datetime: d.getTime().toString(),
        ticket_id: state.ticket_id,
        status: type == "approval" ? 3210 : 3215,
        readingBy: readingList,
        details: [
          {
            key: "status",
            value: "Done",
          },
        ],
      }
      if (h2sPPM) {
        data.h2sPPM = h2sPPM?.toString()
      }
      if (toxicityPPM) {
        data.toxicityPPM = toxicityPPM?.toString()
      }
      if (oxygenPercentage) {
        data.oxygenPercentage = oxygenPercentage?.toString()
      }
      if (lelPercentage) {
        data.lelPercentage = lelPercentage?.toString()
      }

      if (IndustryType() === "Food_Grade") {
        delete data.services
      }
      try {
        const res = await inspectFinish(data)
        //   if(type === 'Select Wash Type') {
        //     navigate('/yard/wash-options',{state:{id:id,item: d.getTime().toString(), services: s}})
        //  }
        if (type === "approval" && res.data.success === true) {
          navigate("/yard/home")
        } else if (type === "begin" && res.data.success === true) {
          localStorage.setItem("startTime", d.getTime().toString())

          if (IndustryType() === "Food_Grade") {
            navigate(`/yard/selectservice/${state.ticket_ref_id}`, {
              state: { item: "In Wash" },
            })
          } else {
            navigate(`/yard/wash_details/${state.ticket_ref_id}`, {
              state: { item: d.getTime().toString(), services: s },
            })
          }
        }
        if (res.data.success === false) {
          message.error({
            content: `Not able to perform the action`,
            duration: 2,
          })
        }
      } catch (e) {
        message.error({
          content: `Not able to perform the action`,
          duration: 2,
        })
      }
    }
  }
  const filterEquipmentDetails = () => {
    const filteredEquipment = props?.yardHandData?.filter((val, index) => {
      if (val.ticket_ref_id == id) {
        return val
      }
    })
    if (filteredEquipment && filteredEquipment.length > 0) {
      setState(...filteredEquipment)
      setTextArea(
        filteredEquipment[0]?.remark ? filteredEquipment[0].remark : ""
      )
      const serve = []

      filteredEquipment[0].services.map((val, index) => {
        serve.push(val.id)
      })

      setPreCheckedServices(serve)

      const imageArr = []
      const item =
        filteredEquipment[0].asset_txns_details?.asset_washout_docs ?? []
      item.map((val, index) => {
        if (val?.doc_name === "Pre Wash Images") {
          imageArr.push({
            doc_id: val.doc_id,
            uid: index,
            name: val.doc_name,
            status: "done",
            url: val.doc_path,
            thumbUrl: val.doc_path,
          })
        }
      })
      setPreWashImages(imageArr ?? [])
    }
  }

  const fetchServices = async () => {
    const result = await fetchServiceDetails(
      state?.asset_details?.equipment_type_details?.param_value
    )
    // const otherService = []
    const additionalService = []
    const cleaningService = []
    if (result.data.success === true) {
      result.data.data.filter((val, index) => {
        if (val["service_group_name.param_description"] == "Cleaning Service") {
          cleaningService.push(val)
        }
        if (
          val["service_group_name.param_description"] == "Additional Service"
        ) {
          additionalService.push(val)
        }
        // if(val['service_group_name.param_description'] == 'Other Service'){
        //     otherService.push(val)
        // }
      })
      const cleanService =
        cleaningService && cleaningService?.length > 0 ? cleaningService : null
      const addtionalServe =
        additionalService && additionalService?.length > 0
          ? additionalService
          : null
      setServices({
        CleaningService: cleanService,
        AdditionalService: addtionalServe,
      })

      const radioSelected = {}
      const clean = cleaningService?.map((val, index) => {
        if (preCheckedServices?.includes(val.id)) {
          radioSelected[val["service_name.param_description"]] = val.id
          return {
            id: val?.id,
            price: val.price,
            service_id: val.service_id,
            name: val["sub_service_name.param_description"],
            status: true,
            tax: val.tax,
            sub_service_id: val.sub_service_id,
          }
        } else {
          return {
            id: val.id,
            price: val.price,
            service_id: val.service_id,
            name: val["sub_service_name.param_description"],
            status: false,
            tax: val.tax,
            sub_service_id: val.sub_service_id,
          }
        }
      })
      setCleanServiceState(
        cleaningService && cleaningService?.length > 0 ? clean : null
      )
      const additional = additionalService?.map((val, index) => {
        if (preCheckedServices?.includes(val.id)) {
          radioSelected[val["service_name.param_description"]] = val.id
          return {
            id: val.id,
            price: val.price,
            service_id: val.service_id,
            name: val["sub_service_name.param_description"],
            status: true,
            tax: val.tax,
            sub_service_id: val.sub_service_id,
          }
        } else {
          return {
            id: val.id,
            price: val.price,
            service_id: val.service_id,
            name: val["sub_service_name.param_description"],
            status: false,
            tax: val.tax,
            sub_service_id: val.sub_service_id,
          }
        }
      })

      setSelectedRadioOptios(radioSelected)
      setAddtionalServiceState(
        additionalService && additionalService?.length > 0 ? additional : null
      )
    }
    if (result.data.success === false) {
      setServices({ failed: true })
      message.error({ content: `failed to fetch services`, duration: 2 })
    }
  }
  const handleServiceState = (state, ind, type) => {
    if (type == "clean") {
      const results = cleanServiceState.filter(({ id: id1 }) =>
        ind.some(id2 => id2 === id1)
      )
      setSelectedService({ ...selectedService, cleanServiceState: results })
    }
    if (type == "additional") {
      const results = additionalServiceState.filter(({ id: id1 }) =>
        ind.some(id2 => id2 === id1)
      )
      setSelectedService({
        ...selectedService,
        additionalServiceState: results,
      })
    }
  }
  const removeImage = (img, url) => {
    const imgArr = [...preWashImage]
    imgArr.map((_img, i) => {
      if (_img.doc_id === img) {
        imageDelete(img).then(res => {
          imgArr.splice(i)
        })
      }
    })
    setPreWashImages(imgArr)
  }
  const onFormFinish = values => {
    //const d = []
    console.log(props?.yardHandData)
    const fields = Object.keys(values) || []
    const filedsData = values
    const sendData = state?.asset_txns_details?.asset_txns_details
    const d = []
    fields.forEach(item => {
      const filteredData = sendData?.find(e => e?.field_name === item) || null
      if (filteredData) {
        const itemData = {
          ...(filteredData?.id && { id: filteredData?.id }),
          key: item,
          value: filedsData[item],
        }
        d.push(itemData)
      } else {
        if (filedsData[item]) {
          const itemData = {
            key: item,
            value: filedsData[item],
          }
          d.push(itemData)
        }
      }
    })
    /**sendData?.length > 0 &&
      sendData.forEach(items => {
        //debugger
        if (fields?.includes(items?.field_name)) {
          debugger
          const item = {
            ...(items?.id && { id: items?.id }),
            key: items?.field_name,
            value: filedsData[items?.field_name],
          }

          d.push(item)
        }
      }) */
    /* for (let i in values) {
      if (values[i]) {
        d.push({
          key: i,
          value: values[i],
        })
      }
    }**/
    onFinish("Select Wash Type", d)
  }
  const onFinishFailedInspection = () => {}
  if (!loading && services) {
    return (
      <>
        <Head />
        <section className="inspection">
          <Header
            headName="Inspection Details"
            LinkName={`/yard/equipment/${id}`}
          />
          <div className="inspection_container">
            {IndustryType() == RAIL_CAR ? (
              <>
                <EquipmentDet data={state} />
                <div className="wash_combo_upload_lt">pre wash images</div>
                <PictureCard
                  resp="resp"
                  style={{ marginTop: 7, marginLeft: 17 }}
                  hide={true}
                  response={e => setImage(e)}
                  data={preWashImage}
                  removeImage={removeImage}
                />
                <TextArea
                  head="Note"
                  placeholder="Enter Car Details"
                  value={textArea}
                  change={setTextArea}
                />
                <Form
                  className="car_drop_form"
                  name="basic"
                  onFinish={values => onFormFinish(values)}
                  onFinishFailed={onFinishFailedInspection}
                  autoComplete="off"
                  layout={"vertical"}
                  form={form}
                  style={{
                    maxWidth: "100%",
                  }}
                >
                  <div className="ticket_details_accordionyrd">
                    <div className="contact_sectionyrd">
                      <div className="contact_containeryrd">
                        {props?.location?.state?.car_type === "Tank Car"
                          ? dataSmall.map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className={
                                      show && item.id === long
                                        ? "contact_accordion_activeyrd"
                                        : "contact_accordionyrd"
                                    }
                                  >
                                    <div
                                      className="contact_accordion_headyrd"
                                      onClick={() => accordionHandler(item)}
                                      style={{
                                        height:
                                          show && item.id === long
                                            ? "7vh"
                                            : "10vh",
                                      }}
                                    >
                                      <h3>{item.title}</h3>
                                      <img
                                        src={Arrow}
                                        alt="icon forword"
                                        style={{
                                          transform:
                                            show && item.id === long
                                              ? "rotate(180deg)"
                                              : "",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        show && item.id === long
                                          ? "contact_accordion_desc_activeyrd"
                                          : "contact_accordion_descyrd"
                                      }
                                    >
                                      {item.description}
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          : dataAcc.map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className={
                                      show && item.id === long
                                        ? "contact_accordion_activeyrd"
                                        : "contact_accordionyrd"
                                    }
                                  >
                                    <div
                                      className="contact_accordion_headyrd"
                                      onClick={() => accordionHandler(item)}
                                      style={{
                                        height:
                                          show && item.id === long
                                            ? "7vh"
                                            : "10vh",
                                      }}
                                    >
                                      <h3>{item.title}</h3>
                                      <img
                                        src={Arrow}
                                        alt="icon forword"
                                        style={{
                                          transform:
                                            show && item.id === long
                                              ? "rotate(180deg)"
                                              : "",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        show && item.id === long
                                          ? "contact_accordion_desc_activeyrd"
                                          : "contact_accordion_descyrd"
                                      }
                                    >
                                      {item.description}
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                      </div>
                    </div>

                    {/* <div className='contact_section'>
          <div className='contact_container'>
            {dataSe.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={
                      shows && item.id === longs
                        ? 'contact_accordion_active'
                        : 'contact_accordion'
                    }
                  >
                    <div
                      className='contact_accordion_headtr'
                      onClick={() => accordionHandlers(item)}
                      style={{
                        height: shows && item.id === longs ? "7vh" : "10vh",
                        borderBottom:
                          shows && item.id === longs ? "1px solid #8B8FAE" : "",
                      }}
                    >
                      <h3>{item.title}</h3>
                      <img
                        src={Arrow}
                        alt="icon forword"
                        style={{
                          transform:
                            shows && item.id === longs ? "rotate(180deg)" : "",
                        }}
                      />
                    </div>
                    <div
                      className={
                        shows && item.id === longs
                          ? 'contact_accordion_desc_active'
                          : 'contact_accordion_desc'
                      }
                    >
                      {item.description}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div> */}
                  </div>
                  {/* <DateTime
                  label_1="date"
                  label_2="time"
                  date={date}
                  time={time}
                  setTime={setTime}
                  setDate={setDate}
                  width="340px"
                /> */}
                  <div className="inspection_begin_content">
                    <CustomButton
                      className="inspection_begin_1"
                      name="Need Approval?"
                      onClick={() => onFinish("approval")}
                    />

                    {/* <CustomButton
                    className="inspection_begin_2"
                    name="Select Wash Type"
                    onClick={() => onFinish("Select Wash Type")}
                  /> */}
                    <Form.Item
                      className="inspection_begin_2"
                      style={{ marginTop: "20px" }}
                    >
                      <Button
                        htmlType="submit"
                        style={{
                          color: "#fff",
                          background: "#38B6FF",
                          borderradius: "7px",
                        }}
                      >
                        Select Wash Type
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            ) : IndustryType() !== "Food_Grade" ? (
              <EquipmentDet data={state} />
            ) : (
              <>
                <EquipmentDet data={state} />
                <EquipmentDoc
                  data={state.asset_txns_details.asset_washout_docs}
                />
                <TextArea head="Note" value={textArea} change={setTextArea} />
                <div className="wash_combo_upload_lt">pre wash images</div>
                <PictureCard
                  resp="resp"
                  style={{ marginTop: 7, marginLeft: 17 }}
                  hide={true}
                  response={e => setImage(e)}
                  data={preWashImage}
                  removeImage={removeImage}
                />
                <DateTime
                  label_1="date"
                  label_2="time"
                  date={date}
                  time={time}
                  setTime={setTime}
                  setDate={setDate}
                />
                <div className="inspection_begin_content">
                  <CustomButton
                    className="inspection_begin_1"
                    name="Need Approval?"
                    onClick={() => onFinish("approval")}
                  />

                  <CustomButton
                    className="inspection_begin_2"
                    name="begin wash"
                    onClick={() => onFinish("begin")}
                  />
                </div>
              </>
              // <YardDetails
              //   button={
              //     <div>
              //       <Button
              //         style={{ marginRight: 20 }}
              //         onClick={() => onFinish('approval')}
              //       >
              //         NEED APPROVAL?
              //       </Button>
              //       <Button
              //         onClick={() => onFinish('begin')}
              //         style={{ color: 'white', background: 'grey' }}
              //       >
              //         BEGIN WASH
              //       </Button>
              //     </div>
              //   }
              //   data={state}
              //   id={id}
              //   pictureCard={
              //     <PictureCard
              //       resp='resp'
              //       style={{ marginTop: 20 }}
              //       hide={true}
              //       response={e => setImage(e)}
              //       data={preWashImage}
              //       removeImage={removeImage}
              //     />
              //   }
              // >
              //   <div>
              //     {' '}
              //     <DateTime
              //       label_1='date'
              //       label_2='time'
              //       date={date}
              //       time={time}
              //       setTime={setTime}
              //       setDate={setDate}
              //     />
              //   </div>
              // </YardDetails>
            )}
            {IndustryType() == RAIL_CAR
              ? ""
              : IndustryType() !== "Food_Grade" && (
                  <>
                    <div className="wash_combo_upload_lt">pre wash images</div>
                    <PictureCard
                      resp="resp"
                      style={{ marginTop: 7, marginLeft: 17 }}
                      hide={true}
                      response={e => setImage(e)}
                      data={preWashImage}
                      removeImage={removeImage}
                    />
                    {/* <InspectionImg /> */}
                    {state?.status_remark && (
                      <>
                        <div style={{ marginTop: 20 }}>
                          <h3 style={{ marginLeft: 10 }}>
                            Manager Approval Notes
                          </h3>
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          <p
                            style={{
                              fontWeight: "normal",
                              fontWeight: "300",
                              fontSize: 18,
                            }}
                          >
                            {state?.status_remark}
                          </p>
                        </div>
                      </>
                    )}
                    <TextArea
                      head="Note"
                      value={textArea}
                      change={setTextArea}
                    />
                    <div style={{ padding: 14 }}>
                      <h4>
                        <b>Want to enter atmospheric reading?</b>
                      </h4>
                      <div onChange={e => setShowExtraFields(e.target.value)}>
                        {console.log(showExtraFields)}
                        <input type="radio" value={1} name="extraField" /> Yes
                        <span style={{ paddingLeft: 10 }} />
                        <input type="radio" value={0} name="extraField" /> No
                      </div>
                    </div>
                    {showExtraFields == 1 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="inspection_begin_content">
                            <h2
                              style={{
                                fontSize: 18,
                                fontWeight: "500",
                                marginTop: 8,
                              }}
                            >
                              Oxygen %
                              <small style={{ marginLeft: 10 }}>
                                (Enter Only between 19.5% and 23%)
                              </small>
                            </h2>
                            <InputNumber
                              style={{
                                width: "150px",
                                border: "1px solid grey",
                              }}
                              value={oxygenPercentage}
                              onChange={e => {
                                setOxygenPercentage(e)
                              }}
                              min={19.5}
                              max={23}
                              precision={2}
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="inspection_begin_content">
                            <h2
                              style={{
                                fontSize: 18,
                                fontWeight: "500",
                                marginTop: 8,
                              }}
                            >
                              H2S PPM
                              <small style={{ marginLeft: 10 }}>
                                (Do not enter if greater than 10ppm)
                              </small>
                            </h2>

                            <InputNumber
                              style={{
                                width: "150px",
                                border: "1px solid grey",
                              }}
                              value={h2sPPM}
                              onChange={e => {
                                setH2sPPM(e)
                              }}
                              min={0}
                              max={10}
                              precision={2}
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="inspection_begin_content">
                            <h2
                              style={{
                                fontSize: 18,
                                fontWeight: "500",
                                marginTop: 8,
                              }}
                            >
                              Toxicity PPM
                              <small style={{ marginLeft: 10 }}>
                                (Do not enter if greater than 10ppm)
                              </small>
                            </h2>
                            <InputNumber
                              style={{
                                width: "150px",
                                border: "1px solid grey",
                              }}
                              value={toxicityPPM}
                              onChange={e => {
                                setToxicityPPM(e)
                              }}
                              min={0}
                              max={10}
                              precision={2}
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="inspection_begin_content">
                            <h2
                              style={{
                                fontSize: 18,
                                fontWeight: "500",
                                marginTop: 8,
                              }}
                            >
                              LEL %
                              <small style={{ marginLeft: 10 }}>
                                (Do not enter if greater than 10%)
                              </small>
                            </h2>
                            <InputNumber
                              style={{
                                width: "150px",
                                border: "1px solid grey",
                              }}
                              value={lelPercentage}
                              onChange={e => {
                                setLelPercentage(e)
                              }}
                              min={0}
                              max={10}
                              precision={2}
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="inspection_begin_content">
                            <h2
                              style={{
                                fontSize: 18,
                                fontWeight: "500",
                                marginTop: 8,
                              }}
                            >
                              Inspected By
                              {/* <small style={{marginLeft:10}}>(Do not enter if greater than 10%)</small> */}
                            </h2>
                            <Select
                              style={{ width: "20%" }}
                              value={readingList}
                              onChange={e => {
                                setReadingList(e)
                              }}
                            >
                              {clientList &&
                                clientList.map(item => (
                                  <Option
                                    value={item.user_id}
                                    category={item.user_id}
                                  >
                                    {item.username}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </>
                    )}
                    {services &&
                    selectedRadioOption &&
                    services?.failed === undefined ? (
                      <Services
                        data={services}
                        selectedRadioOption={selectedRadioOption}
                        cleanServiceState={cleanServiceState}
                        additionalServiceState={additionalServiceState}
                        preCheckedServices={preCheckedServices}
                        checkHandler={(state, index, type) =>
                          handleServiceState(state, index, type)
                        }
                      />
                    ) : services && services?.failed === true ? (
                      <span className="text-center">
                        FAILED TO FETCH SERVICES !!
                      </span>
                    ) : (
                      <span className="text-center">FETCHING SERVICES...</span>
                    )}
                    <DateTime
                      label_1="date"
                      label_2="time"
                      date={date}
                      time={time}
                      setTime={setTime}
                      setDate={setDate}
                    />
                    <div className="inspection_begin_content">
                      <CustomButton
                        className="inspection_begin_1"
                        name="Need Approval?"
                        onClick={() => onFinish("approval")}
                      />

                      <CustomButton
                        className="inspection_begin_2"
                        name="begin wash"
                        onClick={() => onFinish("begin")}
                      />
                    </div>{" "}
                  </>
                )}
          </div>
        </section>
      </>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  yardHandData: state.yardHandData,
})
export default connect(mapStateToProps, null)(Inspection)

import React, { useState, useEffect } from "react"
import style from "../CarWashOptions/carWashOptions.module.css"
import Img1 from "../../images/new/check-box-d.svg"
import Img2 from "../../images/new/check-box-s.svg"
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
} from "antd"
const CarWashOptionsSmall = ({
  service,
  openInput,
  setOpenInput,
  data,
  form,
  callback,
}) => {
  const [selectedService, setSelectedService] = useState([])
  const imageChange = (id, item) => {
    const isAlreadyOpen = openInput?.includes(id)
    if (isAlreadyOpen) {
      const newData = selectedService.filter(items => items.id !== item.id)
      setSelectedService(newData)
      const filterdItem = openInput?.filter(item => item !== id)
      setOpenInput(filterdItem)
    } else {
      setOpenInput([...openInput, id])
      setSelectedService([...selectedService, item])
    }
  }
  useEffect(() => {
    callback && callback(selectedService)
  }, [selectedService])

  useEffect(() => {
    const _data = data?.asset_txns_details?.asset_txns_details
    _data &&
      _data.forEach(item => {
        form.setFieldsValue({
          [item.field_name]: item.field_value,
        })
      })
  }, [])
  useEffect(() => {
    const _data = data?.asset_txns_details?.asset_txns_details
    const first = _data?.find(
      name => name.field_name === "Hopper Car Change Of Service"
    )
    // setOpenInput([...openInput, id])
    console.log(_data)
    console.log(service)
    //const seService=_data.find((item)=>item.field_name)
  }, [service])
  {
    /**openInput?.includes(item?.id)
              ? style.car_wash_option_small
              : */
  }
  return service?.length > 0 ? (
    service?.map((item, index) => {
      return (
        <div className={style.car_wash_option_small_act} key={index}>
          <div className={style.car_wash_option_small_ch}>
            <label htmlFor="Hopper Car Change of Service">
              {item && item["service_name.param_description"]}
            </label>
            {console.log(openInput)}
            <img
              src={openInput?.includes(item?.id) ? Img2 : Img1}
              alt="check images"
              onClick={() => imageChange(item.id, item)}
            />
          </div>
          {/** {openInput?.includes(item?.id) && (
            <Form.Item
              className="car_form_item"
              style={{ width: "100%" }}
              name={item && item["service_name.param_description"]}
              rules={[
                {
                  required: true,
                  message: "Please input your service!",
                },
              ]}
            >
              <Input placeholder={item.desc} />
            </Form.Item>
          )} */}
        </div>
      )
    })
  ) : (
    <h1>There Are No Service</h1>
  )
}
export default CarWashOptionsSmall
